import { z } from 'zod'

export function originAndDestinationShouldNotBeEqual(arg: { origin: string; destination: string }, ctx: z.RefinementCtx) {
  if (arg.origin === arg.destination) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Origin and destination cannot be the same',
      path: ['origin']
    })
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Origin and destination cannot be the same',
      path: ['destination']
    })
  }
}
