import type { LoaderFunctionArgs } from '@remix-run/node'
import { json } from '@remix-run/node'
import { useFetcher, useSearchParams } from '@remix-run/react'
import classNames from 'classnames'
import Downshift from 'downshift'
import { useEffect, useState } from 'react'
import { z } from 'zod'
import { LabelFormatter } from '~/components/label-formatter'
import useDebounce from '~/hooks/useDebounce'
import { RoutescannerApiFetchClient, getDefaultHeaders } from '~/services/routescanner-api.server'
import { encodeSuggestionValue } from '~/utils/encode-helper'

const SuggestionTypeSchema = z.enum(['terminal', 'locode', 'subdivision', 'country', 'address', 'geo'])

export type SuggestionApiItem = z.infer<typeof SuggestionApiItemSchema>
const SuggestionApiItemSchema = z.object({
  id: z.string().optional().nullable(),
  type: SuggestionTypeSchema,
  name: z.string(),
  shortName: z.string().optional().nullable(),
  locode: z.string().optional().nullable(),
  uuid: z.string().optional().nullable(),
  location: z
    .object({
      lat: z.number(),
      lng: z.number()
    })
    .optional()
    .nullable()
})

export type Suggestion = z.infer<typeof SuggestionSchema>
const SuggestionSchema = z.object({
  label: z.string(),
  value: z.string(),
  type: SuggestionTypeSchema,
  locode: z.string().optional().nullable()
})

const SuggestionApiSchema = z.array(SuggestionApiItemSchema)

const AutoCompleteRequestSchema = z.object({
  q: z.string(),
  limit: z.string().nullish(),
  type: SuggestionTypeSchema.nullish()
})

export async function loader({ request }: LoaderFunctionArgs) {
  const defaultHeaders = await getDefaultHeaders(request)
  const routescannerApiFetchClient = new RoutescannerApiFetchClient(defaultHeaders)

  try {
    const url = new URL(request.url)
    const searchParams = new URLSearchParams(url.search)
    const q = searchParams.get('q')
    const limit = searchParams.get('limit')
    const type = searchParams.get('type')
    const result = AutoCompleteRequestSchema.safeParse({ q, limit, type })

    if (!result.success) {
      return json([])
    }

    const response = await routescannerApiFetchClient.fetchSuggestions({ q: result.data.q, limit: parseInt(result.data.limit || '5') })
    const suggestions = SuggestionApiSchema.parse(response)
    const data = suggestions
      .filter(item => (type ? item.type === type : true))
      .map(item => ({
        label: item.name,
        value: encodeSuggestionValue(item),
        type: item.type,
        locode: item.locode
      }))

    const parsed = z.array(SuggestionSchema).parse(data)
    return json(parsed)
  } catch (e) {
    return json([])
  }
}

type SuggestionInputProps = {
  label: string
  type: 'origin' | 'destination'
  inputClassName?: string
  labelClassName?: string
  error?: boolean
  autoUpdateSearchParams?: boolean
  showLabel?: boolean
  disabled?: boolean
  defaultSuggestion?: Suggestion
}
export function SuggestionInput({ disabled, showLabel = true, defaultSuggestion, ...props }: SuggestionInputProps) {
  const [searchParams] = useSearchParams()
  const { setQuery, suggestionsFetcher } = useSuggestionFetcher()
  const searchParamValue = props.type === 'origin' ? 'from' : 'to'
  const searchParamLabel = props.type === 'origin' ? 'fromLabel' : 'toLabel'
  const searchParamType = props.type === 'origin' ? 'fromType' : 'toType'

  const getAutocompleteDefaultValue = () => {
    const paramType = searchParams.get(searchParamType)
    const paramValue = searchParams.get(searchParamValue)
    const paramLabel = searchParams.get(searchParamLabel)
    if (paramType && paramValue && paramLabel) {
      return { type: paramType, value: paramValue, label: paramLabel } as Suggestion
    }
    if (defaultSuggestion) {
      return defaultSuggestion
    }
    return undefined
  }

  const defaultValue = getAutocompleteDefaultValue()

  const onInputValueChange = (value: string) => {
    setQuery(value)
  }

  return (
    <Downshift<Suggestion>
      id={props.type}
      initialSelectedItem={defaultValue}
      onInputValueChange={onInputValueChange}
      defaultHighlightedIndex={0}
      itemToString={item => (item ? item.label : '')}>
      {({ getInputProps, getItemProps, getLabelProps, getMenuProps, isOpen, highlightedIndex, selectedItem, getRootProps }) => (
        <div className="relative">
          <div className="flexflex-col gap-1">
            {showLabel ? (
              <label {...getLabelProps()} className={classNames(props.labelClassName || 'font-bold')}>
                {props.label}
              </label>
            ) : null}
            <div className="flex" {...getRootProps({}, { suppressRefError: true })}>
              <input
                placeholder="City, port or terminal"
                disabled={disabled}
                className={classNames(props.inputClassName || 'input input-sm w-full pl-0', props.error && 'input-error')}
                {...getInputProps()}
              />
              <input
                type="hidden"
                name={props.type}
                value={selectedItem ? `${selectedItem?.type}|${selectedItem?.value}|${selectedItem?.label}` : ''}
              />
            </div>
          </div>
          {suggestionsFetcher.data ? (
            <ul
              className={classNames('absolute z-10 mt-1 max-h-80 w-80 overflow-auto  bg-white p-0 shadow-md lg:w-96', !isOpen ? 'hidden' : '')}
              {...getMenuProps()}>
              {isOpen
                ? suggestionsFetcher.data.map((item, index) => (
                    <li
                      key={item.value}
                      className={classNames(
                        highlightedIndex === index && 'bg-brand-primary-300',
                        selectedItem === item && 'font-bold',
                        'flex flex-col px-3 py-2 shadow-sm'
                      )}
                      {...getItemProps({
                        index,
                        item
                      })}>
                      <LabelFormatter {...item} />
                    </li>
                  ))
                : null}
            </ul>
          ) : null}
        </div>
      )}
    </Downshift>
  )
}

function useSuggestionFetcher() {
  const suggestionsFetcher = useFetcher<Suggestion[]>()
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery] = useDebounce(query, 500)

  useEffect(() => {
    if (debouncedQuery) {
      suggestionsFetcher.submit(
        { q: debouncedQuery },
        {
          method: 'get',
          action: '/fullstack-components/suggestions'
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery])

  return {
    setQuery,
    suggestionsFetcher
  }
}
